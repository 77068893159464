import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const CardContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.card-container {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(3.75)};
    text-align: center;
    background-color: #fff;
    border-radius: 10px;

    & .top-section {
      height: ${({ theme }) => theme.spacing(9)};
    }

    & .title-container h3
        font-size: 18px;
        font-weight: 600;
    }

    & .description-container p {
        color: #000;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & .title-container h3 {
        font-size: 16px;
      }
      & .description-container p {
        font-size: 14px;
      }
    }
  }
`;
