import * as React from "react";
import Box from "@mui/material/Box";
import { EmailContainer } from "./styles";
import { EmailProps } from "./types";
import { PrismicRichText } from "@prismicio/react";

const EmailContact: React.FC<EmailProps> = ({ email }) => {
  return (
    <EmailContainer className={"email-container"}>
      <Box className={"email-title-container"}>
        <PrismicRichText field={email.contact_title.richText} />
      </Box>
      <Box className={"email-target-container"}>
        <PrismicRichText field={email.email.richText} />
      </Box>
    </EmailContainer>
  );
};

export default EmailContact;
