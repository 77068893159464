import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "components/common/seo";
import Top from "./components/top";
import Content from "./components/content";
import { ContactUsContainer } from "./styles";

// interface ContactUsPageProps {}

const ContactUsPage: React.FC<ContactUsPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicContactUs {
        nodes {
          data {
            title {
              richText
            }
            banner_image {
              gatsbyImageData
            }
            secondary_title {
              richText
            }
            title_description {
              richText
            }
            title_sub_description {
              richText
            }
            body {
              ... on PrismicContactUsDataBodyContactContent {
                id
                items {
                  icon_title {
                    richText
                  }
                  icon {
                    gatsbyImageData
                  }
                  icon_description {
                    richText
                  }
                }
              }
              ... on PrismicContactUsDataBodyFooterList {
                id
                items {
                  contact_title {
                    richText
                  }
                  email {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <>
      <Seo />

      <ContactUsContainer className="contact-us-container">
        <Top document={document}></Top>
        <Content content={document.body}></Content>
      </ContactUsContainer>
    </>
  );
};

export default ContactUsPage;
