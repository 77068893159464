import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const ContactUsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.contact-us-container {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.primary.light};
    text-align: center;

    & .card-group-container {
      margin-bottom: ${({ theme }) => theme.spacing(11)};
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & .card-group-container {
        margin-bottom: ${({ theme }) => theme.spacing(6)};
      }
    }
  }
`;
