import * as React from "react";
import Box from "@mui/material/Box";
import { GatsbyImage } from "gatsby-plugin-image";
import { TopContainer } from "../styles";
import { TopProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const Top: React.FC<TopProps> = ({ document }) => {
  return (
    <TopContainer className={"top-container"}>
      <Box className={"top-title-container"}>
        <PrismicRichText field={document.title.richText} />
      </Box>
      <Box className={"banner-image-container"}>
        <GatsbyImage
          className={"banner-image"}
          alt={"banner"}
          image={document.banner_image.gatsbyImageData}
        />
      </Box>
      <Box className={"secondary-title-container"}>
        <PrismicRichText field={document.secondary_title.richText} />
      </Box>
      <Box className={"title-description-container"}>
        <PrismicRichText field={document.title_description.richText} />
      </Box>
      <Box className={"title-sub-description-container"}>
        <PrismicRichText field={document.title_sub_description.richText} />
      </Box>
    </TopContainer>
  );
};

export default Top;
