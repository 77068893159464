import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const EmailContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.email-container {
    text-align: center;
    display: inline-block;
    width: 50%;
    margin-bottom: ${({ theme }) => theme.spacing(6)};

    & .email-title-container h4 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: ${({ theme }) => theme.spacing(2.5)};
    }

    & .email-target-container p {
      font-size: 18px;
      font-weight: 400;
      color: #000;
    }

    & .email-target-container h6 {
      display: none;
      margin-top: 0;
      margin-bottom: 0;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      width: 100%;

      & .email-title-container h4 {
        font-size: 16px;
        margin-bottom: ${({ theme }) => theme.spacing(1.25)};
      }

      & .email-target-container p {
        font-size: 14px;
      }

      & .email-target-container h6 {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
  }
`;
