import * as React from "react";
import Box from "@mui/material/Box";
import { GatsbyImage } from "gatsby-plugin-image";
import { CardContainer } from "./styles";
import { CardProps } from "./types";
import { PrismicRichText } from "@prismicio/react";

const Card: React.FC<CardProps> = ({ card }) => {
  return (
    <CardContainer className={"card-container"}>
      <Box className="top-section">
        <Box className={"icon-container"}>
          <GatsbyImage image={card.icon.gatsbyImageData} alt="" />
        </Box>
        <Box className={"title-container"}>
          <PrismicRichText field={card.icon_title?.richText} />
        </Box>
      </Box>
      <Box className={"description-container"}>
        <PrismicRichText field={card.icon_description?.richText} />
      </Box>
    </CardContainer>
  );
};

export default Card;
