import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const TopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.top-container {
    & .top-title-container {
      font-family: "utopia-std-display";
      max-width: ${({ theme }) => theme.spacing(140)};
      padding: ${({ theme }) => theme.spacing(19, 2, 0)};
      margin: ${({ theme }) => theme.spacing(0, "auto", -6)};

      h1 {
        font-size: 80px;
        font-weight: 400;
        line-height: 1.2;
      }
    }

    & .banner-image-container {
      max-width: 1440px;
      margin: 0 auto;
    }

    & .secondary-title-container {
      h2 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    & .title-description-container {
      h4 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: ${({ theme }) => theme.spacing(1)};
      }
    }

    & .title-sub-description-container {
      p {
        color: #000;
        font-size: 18px;
      }
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      & .top-title-container {
        margin: ${({ theme }) => theme.spacing(0, -1)};
        h1 {
          font-size: 52px;
          font-weight: 400;
          line-height: 1.2;
        }
      }
      & .secondary-title-container h2 {
        font-size: 16px;
        margin-bottom: ${({ theme }) => theme.spacing(1)};
      }

      & .title-description-container h4 {
        font-size: 16px;
        margin-bottom: ${({ theme }) => theme.spacing(3)};
        max-width: ${({ theme }) => theme.spacing(27)};
        margin-left: auto;
        margin-right: auto;
      }

      & .title-sub-description-container p {
        font-size: 14px;
        max-width: ${({ theme }) => theme.spacing(33)};
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

export const ContentContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.content-container {
    max-width: ${({ theme }) => theme.spacing(79)};
    padding: ${({ theme }) => theme.spacing(0, 2, 8)};
    margin: ${({ theme }) => theme.spacing(5, "auto", 0)};

    & .card-group-container {
      display: flex;
      flex-wrap: wrap;

      .card-container {
        width: 100%;
      }

      ${({ theme }: ThemeType) => theme.breakpoints.up("tablet")} {
        .card-container:first-child {
          width: 34%;
          margin-right: ${({ theme }) => theme.spacing(2)};
        }
        .card-container:nth-child(2) {
          flex: 1;
        }
        .card-container:last-child {
          width: 100%;
        }
      }
    }
  }
`;
