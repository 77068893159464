import React from "react";
import ContactUsPage from "../components/pages/contact-us";

interface ContactProps {}

const ContactUs: React.FC<ContactProps> = () => {
  return <ContactUsPage />;
};

export default ContactUs;
