import * as React from "react";
import Box from "@mui/material/Box";
import Card from "../card";
import EmailContact from "../email";
import { ContentContainer } from "../styles";
import { ContentProps } from "../types";

const ContactContent: React.FC<ContentProps> = ({ content }) => {
  return (
    <ContentContainer className={"content-container"}>
      <Box className={"card-group-container"}>
        {content[0].items.map((item, index) => (
          <Card card={item} key={index} />
        ))}
      </Box>
      <Box className={"email-group-container"}>
        {content[1].items.map((item, index) => (
          <EmailContact email={item} key={index} />
        ))}
      </Box>
    </ContentContainer>
  );
};

export default ContactContent;
